<template>
  <v-dialog
    :value="true"
    max-width="290px"
    @keydown.esc="close"
    @click:outside="close"
    scrollable
  >
    <v-sheet class="white">
      <v-card-title class="justify-center mb-3">
        {{ $t('removingBlockedSlot') }}
      </v-card-title>
      <v-card-subtitle class="text-center">
        {{ $t('doYouWantToUnblockThisSlot') }}
        {{ startTime }} - {{ endTime }}
      </v-card-subtitle>
      <v-btn
        min-width="145"
        color="green"
        text
        @click="unblockSlot"
      >
        {{ $t('confirm') }}
      </v-btn>
      <v-btn
        min-width="145"
        color="red"
        text
        @click="close"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-sheet>
  </v-dialog>
</template>

<script>
export default {
    name: 'BlockedSlotDialog',
    props: {
      blockedSlot:{
        type: Object,
        default: () => {}
      }
    },
    data() {
        return {
            dialog: true
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        unblockSlot() {
            this.$emit('unblockSlot')
        }
    },
    computed: {
        startTime() {
            return new Date(this.blockedSlot.startTimeInMillis).toISOString().substr(11, 8)
        },
        endTime() {
            return new Date(this.blockedSlot.endTimeInMillis).toISOString().substr(11, 8)
        }
    }
}
</script>

<style scoped>

</style>
